import { compiledBlock as _compiledBlock } from "million/react";
const CourseLoopElement_1 = _compiledBlock(_props => <div className="card card-bordered" key={_props.v0}>
      <div className="card-pinned">
        <img className="card-img-top" src={_props.v1} alt="A" />

        <div className="card-pinned-top-start">
          {_props.v2}
        </div>

        <div className="card-pinned-bottom-start">
          <div className="d-flex align-items-center flex-wrap">
            <span className="fw-semibold text-white me-1">4.91</span>
            <span className="text-white-70">(1.5k+ reviews)</span>
          </div>
        </div>
      </div>

      <div className="card-body">
        {_props.v3}
      </div>

      <div className="card-footer">
        {_props.v4}
      </div>
    </div>, {
  name: "CourseLoopElement_1",
  portals: ["v2", "v3", "v4"]
});
const CourseLoopElement = function CourseLoopElement({
  index
}) {
  return /*@million jsx-skip*/<CourseLoopElement_1 v0={index + '_course_item'} v1={placeholder_thumbnail_course} v2={<Badge tone="enabled">Bestseller</Badge>} v3={<BlockStack gap="200">
          <small className="card-subtitle">Code</small>
          <Text as="h3" variant="headingLg">
            <Link url="/edu/view/1" removeUnderline>
              Complete Python Bootcamp: Go from zero to hero in Python
            </Link>
          </Text>

          <InlineStack blockAlign="center" align="space-between">
            <Avatar source="" name="A" size="md" />
            <Text as="span" tone="subdued">
              12 bài học
            </Text>
          </InlineStack>
        </BlockStack>} v4={<InlineGrid columns={['twoThirds', 'oneThird']}>
          <BlockStack>
            <Text as="p" tone="subdued">
              <del>$114.99</del>
            </Text>
            <Text as="p">$99.99</Text>
          </BlockStack>
        </InlineGrid>} />;
};
import { block as _block } from "million/react";
import { Avatar, Badge, BlockStack, InlineGrid, InlineStack, Link, Text } from '@shopify/polaris';
import React from 'react';
import placeholder_thumbnail_course from 'media/images/placeholder_thumbnail_course.svg';
export default CourseLoopElement;