import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
export default function ProtectedRoute({
  children
}: any) {
  const {
    isAuthenticated
  } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
}